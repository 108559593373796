import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Pages/Shared/Footer/Footer";
import Header from "../Components/Header/Header";
import './Main.css'

const Main = () => {
  const { pathname } = useLocation();
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.clientHeight;
      const scrolled = window.scrollY;

      const scrollPercentage = (scrolled / (fullHeight - windowHeight)) * 100;
      setScrollWidth(scrollPercentage);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  return (
    <div>
      <Header />
      {/* <div className="scroll-bar bg-secondary" style={{ width: `${scrollWidth}%` }} /> */}
      <Outlet />
      <Footer />
    </div>
  );
};

export default Main;

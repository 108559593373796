import React from "react";
import img from "../Images/Clariss-Logo.webp";

const Refund = () => {
  return (
    <div className="px-[6%] py-[10%] sm:px-[10%] sm:py-[6%]">
      <img className="mb-6 mx-auto w-[200px]" src={img} alt="" />
      <p className="text-xl text-center font-bold mb-16">
        Clariss Bangladesh Return Policy
      </p>
      <p className="font-bold">Thanks for shopping from Clariss Bangladesh.</p>
      <p>If you are unsatisfied with your purchase, we're here to help.</p>
      <p className="font-bold mt-6">Clariss Bangladesh Return Policy</p>
      <p>
        1. You have 3 calendar days to return an item from the date you received
        it.
        <br />
        2. To be eligible for a return, your item must be unused and in the same
        condition, youreceived it.
        <br />
        3. Your item must be in its original packaging.
        <br />
        4. Your item needs to have a receipt or proof of purchase.
      </p>
      <p className="font-bold mt-6">Clariss Bangladesh Refund Policy</p>
      <p>
        1. Once we receive your item, we will inspect it and notify you that we
        have received your returned item.
        <br />
        2. We will immediately notify you of the status of your refund after
        inspecting the item.
        <br />
        3. If your return is approved, we will initiate a refund to your method
        of payment.
        <br />
        4. You will receive the credit within a few days, depending on the
        refund issues.
      </p>
      <p className="font-bold mt-6">
        Clariss Bangladesh Delivery Charge Policy
      </p>
      <p>
        1. You will be responsible for paying for your own delivery charge for
        returning your item.
        <br />
        2. Delivery charges are nonrefundable.
        <br />
        3. If you receive a refund, the cost of return delivery will be deducted
        from your refund.
        <br />
      </p>
      <p className="font-bold mt-6">Contact Clariss Bangladesh</p>
      <p>
        If you have any questions on how to return your item to us:
        <span className="font-bold"> Inbox us on Facebook. </span> Or you can
        Contact us on{" "}
        <span
          onClick={() => {
            window.location.href = `tel:${+8809613160160}`;
          }}
          className="font-bold cursor-pointer"
        >
          09613-160160
        </span>{" "}
        this number.
      </p>
      <p className="mt-4">
        <span className="font-bold">Note:</span> Clariss Bangladesh holds the
        terms and conditions of the Return & Refund Policy.
      </p>
    </div>
  );
};

export default Refund;

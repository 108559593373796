import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ThemeContext } from "../../Contexts/ThemeContext";
import Product from "../../Components/Shared/Product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faList } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Helmet } from "react-helmet-async";
import "./CategoryPage.css";

const CategoryPage = () => {
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { category } = useParams();
  const [list, setList] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(1000);
  const [sortBy, setSortBY] = useState("Recommanded");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch(
      `https://clariss-backend.vercel.app/categoryProductCount?name=${category}`
    )
      .then((res) => res.json())
      .then((data) => {
        const count = data.count;
        const pages = Math.ceil(count / 50);
        setPageCount(pages);
      });

    fetch(`https://clariss-backend.vercel.app/categories`)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
      });
  }, [category]);

  useEffect(() => {
    setCategoryProducts([]);
    setLoading(true);
    fetch(
      `https://clariss-backend.vercel.app/getProductsByCategories?name=${category}&page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setCategoryProducts(data);
        setLoading(false);
      });
    window.scrollTo(0, 0);
  }, [category, page]);

  useEffect(() => {
    categoryProducts.sort((a, b) => {
      if (a.stock_quantity > 0 && !b.stock_quantity < 1) return -1; // a is in stock, b is out of stock
      if (!a.stock_quantity < 1 && b.stock_quantity > 0) return 1; // a is out of stock, b is in stock
      return 0; // both are either in stock or out of stock
    });
    if (sortBy === "Price: Low to High") {
      const filtered = filterProductsByPriceLowToHigh(categoryProducts);
      setFilteredProducts(filtered);
    }
    if (sortBy === "Price: High to Low") {
      const filtered = filterProductsByPriceHighToLow(categoryProducts);
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [sortBy, categoryProducts]);

  const handleFilter = () => {
    const filtered = categoryProducts.filter(
      (product) => product.regular_price >= from && product.regular_price <= to
    );
    setFilteredProducts(filtered);
  };
  const handleReset = () => {
    setFilteredProducts([]);
  };
  const filterProductsByPriceLowToHigh = (products) => {
    products.sort((a, b) => a.price - b.price);
    return products;
  };
  const filterProductsByPriceHighToLow = (products) => {
    products.sort((a, b) => b.price - a.price);
    return products;
  };

  const meta_description = categories.find(
    (item) => item?.name?.toLowerCase() == category
  )?.meta_description;

  return (
    <div
      className={`w-[95%] mx-auto mb-20 ${
        window.innerWidth >= 1920 ? "2xl:w-[65%]" : "2xl:w-[80%]"
      }`}
    >
      <Helmet>
        <title>{category?.toUpperCase()} - Clariss Bangladesh</title>
        <meta name="description" content={meta_description} />
        <link
          rel="canonical"
          href={`https://clariss.com.bd/product-category/${category}`}
        />
        {/* Add JSON-LD structured data for the category */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "ItemList",
              "name": "${category?.toUpperCase()}",
              "itemListElement": [
                ${categoryProducts
                  .map(
                    (product, index) => `
                  {
                    "@type": "ListItem",
                    "position": ${index + 1},
                    "url": "https://clariss.com.bd/product/${product?.slug}"
                  }
                `
                  )
                  .join(",")}
              ]
            }
          `}
        </script>
      </Helmet>
      <div className="my-10 w-[90%] 2xl:w-full lg:w-full mx-auto">
        <p className="text-[14px] font-semibold">
          Home
          <FontAwesomeIcon
            className="mx-2"
            icon={faCaretRight}
          ></FontAwesomeIcon>
          {category?.toUpperCase()}
        </p>
      </div>
      <h1
        className={`w-[90%] 2xl:w-full lg:w-full mx-auto ${
          category?.includes("top 10") ? "text-center" : ""
        } font-bold my-10 text-[22px]`}
      >
        {category?.toUpperCase()}
      </h1>

      <div className="hidden lg:flex items-center mb-10">
        <button onClick={() => setList(false)}>
          <img
            width="25"
            height="25"
            src="https://img.icons8.com/sf-regular-filled/48/grid.png"
            alt="grid"
            className="inline mt-[-4px]"
          />
          Grid
        </button>
        <button onClick={() => setList(false)}>
          <FontAwesomeIcon className="mx-2" icon={faList}></FontAwesomeIcon>List
        </button>
      </div>

      {list ? (
        <div className="list-container grid gap-6 grid-cols-1">
          {categoryProducts?.map((product) => (
            <Product key={product._id} list={list} product={product}></Product>
          ))}
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row gap-10">
          <div className="hidden lg:block lg:w-[25%] bg-[#F2F6D5] rounded-3xl p-6 shadow-lg shadow-[#ccccc]">
            <p className="mb-2 font-bold">SORT BY : </p>
            <div className=" sticky top-[250px]">
              <select
                onChange={(e) => setSortBY(e.target.value)}
                className="px-4 py-2 rounded-lg font-bold"
              >
                <option>Recommanded</option>
                <option>New Launches</option>
                <option>Price: High to Low</option>
                <option>Price: Low to High</option>
              </select>
            </div>
            <div className="w-[100%] mt-6 py-6 px-4 bg-primary rounded-lg sticky top-[300px]">
              <div className="bg-[#cccccc] h-[1px]"></div>
              <div className="flex justify-between items-center">
                <p className="text-sm my-2 font-bold">SHOP BY PRODUCT TYPE</p>
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="font-bold text-2xl"
                >
                  {isOpen ? "-" : "+"}
                </button>
              </div>
              <div className="bg-[#cccccc] h-[1px]"></div>
              <div
                className={`flex flex-col overflow-hidden ease-in-out duration-200 mt-4 ${
                  isOpen ? "h-fit-content" : "h-0"
                }`}
              >
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/face wash"
                >
                  Face Wash
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/lotion"
                >
                  Lotion
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/fragrances"
                >
                  Fragrances
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/baby care"
                >
                  Baby Care
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/food-oil"
                >
                  Food Oil
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/canned food"
                >
                  Canned Food
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200 mb-4"
                  to="/product-category/honey"
                >
                  Honey
                </Link>
                <div className="bg-[#cccccc] h-[1px]"></div>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm my-2 font-bold">SHOP BY CONCERN</p>
                <button
                  onClick={() => setIsOpen1(!isOpen1)}
                  className="font-bold text-2xl"
                >
                  {isOpen1 ? "-" : "+"}
                </button>
              </div>
              <div className="bg-[#cccccc] h-[1px]"></div>
              <div
                className={`flex flex-col overflow-hidden ease-in-out duration-200 mt-4 ${
                  isOpen1 ? "h-fit-content" : "h-0"
                }`}
              >
                <Link
                  className="hover:text-accent ease-in-out duration-200"
                  to="/product-category/personal care"
                >
                  Personal Care
                </Link>
                <Link
                  className="hover:text-accent ease-in-out duration-200 mb-4"
                  to="/product-category/food care"
                >
                  Food Care
                </Link>
                <div className="bg-[#cccccc] h-[1px]"></div>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm my-2 font-bold">PRICE</p>
                <button
                  onClick={() => setIsOpen2(!isOpen2)}
                  className="font-bold text-2xl"
                >
                  {isOpen2 ? "-" : "+"}
                </button>
              </div>
              <div className="bg-[#cccccc] h-[1px]"></div>
              <div
                className={`flex flex-col overflow-hidden ease-in-out duration-200 mt-4 ${
                  isOpen2 ? "h-fit-content" : "h-0"
                }`}
              >
                <input
                  onChange={(e) => setFrom(e.target.value)}
                  placeholder="From"
                  type="number"
                />
                <input
                  onChange={(e) => setTo(e.target.value)}
                  placeholder="To"
                  type="number"
                />
                <button
                  onClick={handleFilter}
                  className="bg-accent text-primary font-bold px-4 py-1 rounded-lg hover:bg-secondary ease-in-out duration-200 mt-2"
                >
                  Filter
                </button>
                {filteredProducts.length > 0 && (
                  <button
                    onClick={handleReset}
                    className="text-center mx-auto mt-4 text-[#ff0000] hover:underline"
                  >
                    Reset
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>
            {category === "food care" ? (
              <img
                src="https://scontent.fdac11-1.fna.fbcdn.net/v/t1.6435-9/69633867_2481026685555466_8621475302813466624_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=2a1932&_nc_ohc=NOYp-1FdjOYQ7kNvgGPcy2m&_nc_ht=scontent.fdac11-1.fna&_nc_gid=ATvcleFtezGBfm8n7O_FJN1&oh=00_AYDHjwswPn3vb250ajUc5qBbeKMY_L5O9f6USRq_NhRN0Q&oe=6708784E"
                alt=""
                className="mb-10 rounded-xl shadow-lg"
              />
            ) : category === "personal care" ? (
              <img
                src="https://scontent.fdac11-1.fna.fbcdn.net/v/t1.6435-9/71835944_2507530059571795_2104496595939622912_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=2a1932&_nc_ohc=I3Ksm0jvS4EQ7kNvgEk7ObT&_nc_ht=scontent.fdac11-1.fna&oh=00_AYAtqgI9myFbOSnSmR4PQBFjXIhvgACKUkL8ocdq4sZTkA&oe=6708A203"
                alt=""
                className="mb-10 rounded-xl shadow-lg"
              />
            ) : category === "face wash" ? (
              <img
                src="https://scontent.fdac11-2.fna.fbcdn.net/v/t39.30808-6/280758953_3347465848911541_7830222135097039981_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=cc71e4&_nc_ohc=ZdNTd8HWvuUQ7kNvgG7bPiU&_nc_ht=scontent.fdac11-2.fna&oh=00_AYBlM28YCPBXh65XUl1_-mID6bYP6rktjc8DEDq3lxjhXw&oe=66E6FBA4"
                alt=""
                className="mb-10 rounded-xl shadow-lg"
              />
            ) : category === "fragrances" ? (
              <img
                src="https://luvit.com.bd/wp-content/uploads/2024/09/Screenshot-2024-09-11-105449.png"
                alt=""
                className="mb-10 rounded-xl shadow-lg mx-auto"
              />
            ) : category === "food-oil" ? (
              <img
                src="https://scontent.fdac11-2.fna.fbcdn.net/v/t1.6435-9/57308959_2356496531341816_4425468855086743552_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=2a1932&_nc_ohc=pnWULqo3WNkQ7kNvgFd0Uwf&_nc_ht=scontent.fdac11-2.fna&_nc_gid=AgJHGBP6ssiVL0nNubHxcFg&oh=00_AYCuh7nZY5K9NSuerTBqi78cNWHWwX5Bx-jCwIdg3YDYCw&oe=67088388"
                alt=""
                className="mb-10 rounded-xl shadow-lg mx-auto"
              />
            ) : category === "canned food" ? (
              <img
                src="https://scontent.fdac11-2.fna.fbcdn.net/v/t1.6435-9/160215951_3007895156201947_3057132248590213633_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=13d280&_nc_ohc=-7riO1uYDckQ7kNvgEMNPPi&_nc_ht=scontent.fdac11-2.fna&_nc_gid=ABU6rthfHLUYcVhp6Jv1wF_&oh=00_AYCnnmvhVU4uZye1oAHE504J6k4IXrxsrn6CDInc_ab_kg&oe=6708863C"
                alt=""
                className="mb-10 rounded-xl shadow-lg mx-auto"
              />
            ) : category === "honey" ? (
              <img
                src="https://i.ibb.co.com/qrJ3M6v/Untitled-design.png"
                alt=""
                className="mb-10 rounded-xl mx-auto"
              />
            ) : category === "baby care" ? (
              <img
                src="https://scontent.fdac11-2.fna.fbcdn.net/v/t31.18172-8/17039092_1820366904954784_4987931322751857537_o.jpg?_nc_cat=109&ccb=1-7&_nc_sid=2a1932&_nc_ohc=7u6FgfX12AsQ7kNvgEUHb6V&_nc_ht=scontent.fdac11-2.fna&_nc_gid=AXqT52jadIrV5eysGsKqnlg&oh=00_AYDknn5yIlMq8VpPPuzuKHVUfBjM9OFrVyWGDIXFk94_gA&oe=6708A5E4"
                alt=""
                className="mb-10 rounded-xl shadow-lg"
              />
            ) : (
              ""
            )}

            <div className="grid grid-cols-2 gap-y-8 lg:gap-6 lg:grid-cols-2 xl:grid-cols-3 lg:w-[100%]">
              {loading ? (
                <div className="flex justify-center items-center h-[50vh] w-[100vw] lg:w-[55vw]">
                  <ThreeDots
                    height="100"
                    width="100"
                    radius="10"
                    color="#106836"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                (filteredProducts.length > 0
                  ? filteredProducts
                  : categoryProducts
                )?.map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))
              )}
            </div>
          </div>
        </div>
      )}
      {/*.................. pagination ....................*/}
      {pageCount > 1 && (
        <div className="pages mb-[100px]">
          {[...Array(pageCount).keys()].map((index) => (
            <button
              className={page == index ? "selected" : ""}
              onClick={() => setPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {/*.................. pagination ....................*/}
    </div>
  );
};

export default CategoryPage;

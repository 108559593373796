import { Link } from "react-router-dom";
import leaf1 from "../Images/leaf1.png";
import leaf2 from "../Images/leaf2.png";
import face from "../Images/Face-care.jpg";
import hair from "../Images/hair-care.jpg";
import Body from "../Images/Body-care.jpg";
import Baby from "../Images/Baby-care.jpg";
import Fragrances from "../Images/Fragrance.jpg";

const ShopByCategory = () => {
  return (
    <div className="overflow-hidden">
      <div className="relative">
        {/* <img
          className="w-[120px] lg:w-[300px] absolute bottom-0 lg:bottom-[50px] left-[-30px] lg:left-[-80px]"
          alt=""
          src={leaf1}
        /> */}
        <img
          className="w-[120px] lg:w-[300px] absolute top-0 right-[-20px] lg:right-0"
          alt=""
          src={leaf2}
        />
        <div
          className={`flex flex-col px-[10px] items-center sm:px-[20px] lg:px-[0px] lg:w-[90%] mx-auto ${
            window.innerWidth >= 1920 ? "2xl:w-[65%]" : "2xl:w-[80%]"
          }`}
        >
          <h2 className="text-center text-xl text-accent font-bold mt-[50px] sm:text-2xl">
            SHOP BY CATEGORY
          </h2>
          <div className="w-[100px] mx-auto h-1 bg-accent my-6 mb-10"></div>
          <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 lg:gap-6 justify-center items-center">
            <Link to="product-category/face wash" to="product-category/face wash" className="flex flex-col items-center gap-4 ">
              <img className="" alt="" src={face} />
              <p className="font-bold">FACE CARE</p>
            </Link>
            <Link to="product-category/hair care" className="flex flex-col items-center gap-4 ">
              <img className="" alt="" src={hair} />
              <p className="font-bold">HAIR CARE</p>
            </Link>
            <Link to="product-category/fragrances" className="flex flex-col items-center gap-4 ">
              <img className="" alt="" src={Fragrances} />
              <p className="font-bold">FRAGRANCES</p>
            </Link>
            <Link to="product-category/body care" className="flex flex-col items-center gap-4 ">
              <img className="" alt="" src={Body} />
              <p className="font-bold">BODY CARE</p>
            </Link>
            <Link to="product-category/baby care" className="flex flex-col items-center gap-4 ">
              <img className="" alt="" src={Baby} />
              <p className="font-bold">BABY CARE</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopByCategory;

import "./App.css";
import { ThemeContext } from "./Contexts/ThemeContext";
import { Navigate, RouterProvider, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import router from "./Routes/Routes";
import useProduct from "./Hooks/useProduct";
import { useEffect, useState } from "react";
import useCart from "./Hooks/useCart";
import useOrder from "./Hooks/useOrder";
import useScroll from "./Hooks/useScroll";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "./firebase.init";
import Product from "./Components/Shared/Product";
import ReactGA from "react-ga4";
ReactGA.initialize("G-14MXHSJLXW");

function App() {
  const [products, loading] = useProduct();
  const [cart, setCart] = useCart(products);
  const [searchText, setSearchText] = useState("");
  const [orderList, setOrderList] = useOrder();
  const [scrollPosition] = useScroll();
  const [freeProduct, setFreeProduct] = useState([]);
  const [favProducts, setFavProducts] = useState([]);
  const [summerMakeup, setSummerMakeup] = useState([]);
  const [nudeMakeup, setNudeMakeup] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [allOrder, setAllOrder] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [blogLoading, setblogLoading] = useState(false);
  let [user, userLoading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdminLoading, setIsAdminLoading] = useState(false);
  const [selectedComboproduct1, setSelectedComboproduct1] = useState({});
  const [selectedComboproduct2, setSelectedComboproduct2] = useState({});
  const [selectedComboproduct3, setSelectedComboproduct3] = useState({});
  const [selectedComboproduct4, setSelectedComboproduct4] = useState({});
  const [selectedComboproduct5, setSelectedComboproduct5] = useState({});

  const [comboDiscount, setComboDiscount] = useState(0);
  let comboProducts = [
    selectedComboproduct1,
    selectedComboproduct2,
    selectedComboproduct3,
  ];
  let bogoProducts = [selectedComboproduct1, selectedComboproduct2];
  let bigComboProducts = [
    selectedComboproduct1,
    selectedComboproduct2,
    selectedComboproduct3,
    selectedComboproduct4,
    selectedComboproduct5,
  ];
  // let location = useLocation();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Clariss Bangladesh",
    });
  }, []);

  useEffect(() => {
    setIsAdminLoading(true);
    if (user?.phoneNumber) {
      fetch(
        `https://clariss-backend.vercel.app/users/admin/${user?.phoneNumber}`
      )
        .then((res) => res.json())
        .then((data) => {
          setIsAdmin(data.isAdmin);
          setIsAdminLoading(false);
        });
    } else {
      setTimeout(() => {
        setIsAdminLoading(false);
        <Navigate to="/customerDashboard" replace />;
      }, 5000);
    }
    if (user?.email) {
      fetch(`https://clariss-backend.vercel.app/users/admin1/${user?.email}`)
        .then((res) => res.json())
        .then((data) => {
          setIsAdmin(data.isAdmin);
          setIsAdminLoading(false);
        });
    } else {
      setTimeout(() => {
        setIsAdminLoading(false);
        <Navigate to="/customerDashboard" replace />;
      }, 5000);
    }
  }, [user?.phoneNumber, user?.email]);

  useEffect(() => {
    fetch("https://clariss-backend.vercel.app/getProductsByTags?name=food care")
      .then((res) => res.json())
      .then((data) => setFavProducts(data));
    fetch("https://clariss-backend.vercel.app/orders")
      .then((res) => res.json())
      .then((data) => setAllOrder(data));
    fetch(
      "https://clariss-backend.vercel.app/getProductsByTags?name=personal care"
    )
      .then((res) => res.json())
      .then((data) => setSummerMakeup(data));
    fetch("https://clariss-backend.vercel.app/getProductsByTags?name=Lipstick")
      .then((res) => res.json())
      .then((data) => setNudeMakeup(data));
  }, []);

  useEffect(() => {
    if (searchText.length > 2) {
      const result = products?.filter((product) =>
        product.name.toLowerCase().includes(searchText?.toLowerCase())
      );
      setSearchedProducts(result);
    }
  }, [searchText]);

  useEffect(() => {
    setblogLoading(true);
    fetch(`https://clariss-backend.vercel.app/getBlogs`)
      .then((res) => res.json())
      .then((data) => {
        setBlogs(data);
        setblogLoading(false);
      });
  }, []);

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <ThemeContext.Provider
      value={{
        products,
        blogs,
        blogLoading,
        allOrder,
        loading,
        favProducts,
        summerMakeup,
        nudeMakeup,
        searchedProducts,
        cart,
        setCart,
        orderList,
        setOrderList,
        searchText,
        setSearchText,
        freeProduct,
        setFreeProduct,
        appliedCoupon,
        setAppliedCoupon,
        user,
        isAdmin,
        isAdminLoading,
        userLoading,
        setSelectedComboproduct1,
        setSelectedComboproduct2,
        setSelectedComboproduct3,
        setSelectedComboproduct4,
        setSelectedComboproduct5,
        selectedComboproduct1,
        selectedComboproduct2,
        selectedComboproduct3,
        selectedComboproduct4,
        selectedComboproduct5,
        comboProducts,
        bigComboProducts,
        comboDiscount,
        setComboDiscount,
        bogoProducts,
      }}
    >
      <RouterProvider router={router}></RouterProvider>

      <svg
        title="Back To Top"
        className={`transition-all cursor-pointer w-10 h-10  duration-300 ease-in-out fixed bottom-[100px] right-8 ${
          scrollPosition > 1000
            ? "opacity-0 lg:opacity-100"
            : "opacity-0 pointer-events-none"
        }`}
        onClick={() => goToTop()}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        id="up-button"
      >
        <path d="M65.4 44.6c.8.8.8 2 0 2.8-.4.4-.9.6-1.4.6s-1-.2-1.4-.6L52 36.8V68c0 1.1-.9 2-2 2s-2-.9-2-2V36.8L37.4 47.4c-.4.4-.9.6-1.4.6s-1-.2-1.4-.6c-.8-.8-.8-2 0-2.8l14-14 .3-.3c.1 0 .1-.1.2-.1s.1-.1.2-.1.1 0 .2-.1h1c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.1 0 .1.1.2.1 0 0 .1 0 .1.1l.3.3 13.9 13.9zM95 15v70c0 5.5-4.5 10-10 10H15C9.5 95 5 90.5 5 85V15C5 9.5 9.5 5 15 5h70c5.5 0 10 4.5 10 10zm-4 0c0-3.3-2.7-6-6-6H15c-3.3 0-6 2.7-6 6v70c0 3.3 2.7 6 6 6h70c3.3 0 6-2.7 6-6V15z"></path>
      </svg>

      <Toaster />
    </ThemeContext.Provider>
  );
}

export default App;

// https://clariss-backend.vercel.app/

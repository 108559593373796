import { ThemeContext } from "../../Contexts/ThemeContext";
import useScroll from "../../Hooks/useScroll";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const PersonalCare = () => {
  const [linkOneHover, setLinkOneHover] = useState(false);
  const [scrollPosition] = useScroll();
  const [subMenuHover, setSubMenuHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setLinkOneHover(true)}
      onMouseLeave={() => setLinkOneHover(false)}
      className={`transition-all ease-in-out min-h-[65px] duration-300 flex items-center relative`}
    >
      <Link
        to={`/product-category/personal care`}
        className={`mr-10 block font-bold ${
          scrollPosition > 80
            ? "hover:text-accent font-bold text-secondary"
            : "text-secondary hover:text-accent font-bold"
        }`}
      >
        PERSONAL CARE
        <FontAwesomeIcon
          className="w-3 ms-2"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </Link>

      {/* Face Hover product-Category  */}

      <div
        className={`${
          linkOneHover
            ? "absolute text-start pt-[28px] w-[200px] top-[45px] bg-primary right-0 rounded"
            : "hidden"
        }  text-black `}
      >
        <div className="border border-accent border-t-0 rounded shadow-lg px-4 pb-4 pt-4 bg-[#BFD231] bg-opacity-20">
          <Link
            to="/product-category/face wash"
            className=" hover:text-accent font-bold block"
          >
            Face Wash
          </Link>
          <div className="h-[1px] my-[10px] w-[100%] bg-accent"></div>
          <Link
            to="/product-category/shampoo"
            className=" hover:text-accent font-bold block"
          >
            Shampoo
          </Link>
          {/* <div className="h-[1px] my-[10px] w-[100%] bg-accent"></div>
          <Link
            to="/product-category/hair & body Wash"
            className=" hover:text-accent font-bold block"
          >
            Hair & Body Wash
          </Link> */}
          {/* <div className="h-[1px] my-[10px] w-[100%] bg-accent"></div>
          <Link
            to="/product-category/fairness cream"
            className=" hover:text-accent font-bold block"
          >
            Fairness Cream
          </Link> */}
          <div className="h-[1px] my-[10px] w-[100%] bg-accent"></div>
          <Link
            to="/product-category/body lotion"
            className=" hover:text-accent font-bold block"
          >
            Lotion
          </Link>
          <div className="h-[1px] my-[10px] w-[100%] bg-accent"></div>
          <Link
            to="/product-category/fragrances"
            className=" hover:text-accent font-bold block"
          >
            Fragrance
          </Link>
          <div className="h-[1px] my-[10px] w-[100%] bg-accent"></div>
          <Link
            to="/product-category/baby care"
            className=" hover:text-accent font-bold block"
          >
            Baby Care
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PersonalCare;

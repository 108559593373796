import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./Featured.css";
import fragrance from "../../Images/fragrances_cover.jpg";
import facewash from "../../Images/facewash_cover.jpg";
import neem from "../../Images/neem_cover.jpg";
import shampoo from "../../Images/shampoo.png";
import { Link } from "react-router-dom";
import leaf7 from "../../Images/leaf7.png";
import leaf6 from "../../Images/leaf6.png";

const Featured = () => {
  return (
    <div>
      <div className="flex flex-col items-center px-[10px] pt-10 mx-auto relative">
        {/* <img className="absolute w-[80px] lg:w-auto top-20 lg:top-0 left-0 " alt="" src={leaf6} /> */}
        <img className="absolute w-[120px] lg:w-[350px] right-0 lg:right-[-60px] top-[40%] fa-rotate-270 hidden sm:block" alt="" src={leaf6} />
        <p className="text-center">
          <span className="text-accent text-4xl font-bold">EMBRACE</span>{" "}
          <span className="text-secondary ms-2 text-4xl font-bold">BEAUTY</span>
        </p>
        <p className="text-center text-accent font-bold text-2xl mt-4">
          A Cruelty-Free Collection
        </p>
        {/* <div
          className={`flex flex-row flex-wrap lg:flex-row mx-auto w-[96%] 2xl:w-[1200px] mt-10`}
        >
          <div className="flex flex-col items-center w-[50%] sm:w-[12%]">
            <img
              src="https://clarissinc.com/wp-content/uploads/2022/08/Cruelty-Free.png"
              alt=""
            />
            <p className="font-bold text-center">CRUELTY FREE</p>
          </div>
          <div className="flex flex-col items-center w-[50%] sm:w-[12%]">
            <img
              src="https://clarissinc.com/wp-content/uploads/2022/08/Highest-Natural-Score.png"
              alt=""
            />
            <p className="font-bold text-center">HIGHEST NATURAL SCORE</p>
          </div>
          <div className="flex flex-col items-center w-[50%] sm:w-[12%]">
            <img
              src="https://clarissinc.com/wp-content/uploads/2022/08/Globally-sourced-ingredients.png"
              alt=""
            />
            <p className="font-bold text-center">
              GLOBALLY SOURCED INGREDIENTS
            </p>
          </div>
          <div className="flex flex-col items-center w-[50%] sm:w-[12%]">
            <img
              src="https://clarissinc.com/wp-content/uploads/2022/08/100_-Vegan.png"
              alt=""
            />
            <p className="font-bold text-center">100% VEGAN</p>
          </div>
          <div className="flex flex-col items-center w-[50%] sm:w-[12%]">
            <img
              src="https://clarissinc.com/wp-content/uploads/2022/08/pH-Balanced.png"
              alt=""
            />
            <p className="font-bold text-center">PH BALANCED</p>
          </div>
          <div className="flex flex-col items-center w-[50%] sm:w-[12%]">
            <img
              src="https://clarissinc.com/wp-content/uploads/2022/08/Affordable-Luxury.png"
              alt=""
            />
            <p className="font-bold text-center">AFFORDABLE LUXURY</p>
          </div>
          <div className="flex flex-col items-center w-[50%] sm:w-[12%]">
            <img
              src="https://clarissinc.com/wp-content/uploads/2022/08/Toxin-Free-Lifestyle.png"
              alt=""
            />
            <p className="font-bold text-center">TOXIN FREE LIFESTYLE</p>
          </div>
          <div className="flex flex-col items-center w-[50%] sm:w-[12%]">
            <img
              src="https://clarissinc.com/wp-content/uploads/2022/08/Refreshing-Fragrance-Experience.png"
              alt=""
            />
            <p className="font-bold text-center">
              REFRESHING FRAGRANCE EXPERIENCE
            </p>
          </div>
        </div> */}
        <div
          className={`flex flex-row flex-wrap lg:flex-row mx-auto w-[96%] 2xl:w-[1200px] mt-10`}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Link to="/product/clariss-neem-face-wash" className="">
              <img
                alt=""
                src={neem}
                className="w-[100%] cursor-pointer animated-element"
              />
            </Link>
            <Link to="/product-category/shampoo" className="">
              <img
                alt=""
                src={shampoo}
                className="w-[100%] cursor-pointer animated-element"
              />
            </Link>
            <Link to="/product-category/face wash" className="">
              <img
                alt=""
                src={facewash}
                className="w-[100%] cursor-pointer animated-element"
              />
            </Link>
            <Link to="/product-category/fragrances" className="">
              <img
                alt=""
                src={fragrance}
                className="w-[100%] cursor-pointer animated-element"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;

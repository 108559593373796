import React from "react";
// import img from "../Images/banner1.png";
// import img1 from "../Images/banner2.png";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <div className="p-[6%] sm:px-[10%] sm:py-[2%]">
      <Helmet>
        <title>{`About Us - Clariss Bangladesh`}</title>
        <meta
          name="description"
          content="At Clariss Bangladesh, we’re passionate about skincare that’s inspired by nature. We believe that the key to true beauty lies in embracing the natural world around us. That’s why we’ve carefully crafted a range of skincare products using certified organic ingredients, so you can confidently be the best version of yourself."
        />
      </Helmet>
      <p className="font-bold my-10 text-center">
        <span className="text-accent font-bold text-4xl mr-2">Clariss</span>
        <span className="text-accent font-bold text-black text-4xl">
          Bangladesh
        </span>
      </p>
      <p className="text-3xl text-secondary font-bold my-10 text-center sm:w-[600px] mx-auto">
        Welcome to Clariss Bangladesh – where the beauty of nature meets the
        beauty in you.
      </p>
      <div className="flex flex-col lg:flex-row gap-10 items-center justify-center">
        <div className="w-[100%] lg:w-[50%]">
          <p className="text-3xl text-center font-bold mb-6">
            About Clariss Bangladesh Brand
          </p>
          {/* <img className="w-[100%] " src={img} alt="" /> */}

          <p className="font-bold text-[18px]">
            Clariss is a global beauty company with a portfolio of Clariss
            brands in the categories of Fragrances, Skin Care and Body Care. Our
            company produces quality products and is also involved in the
            promotion of the products produced. Customer satisfaction is at the
            forefront of our work which drives us to fulfill different demands
            and expectations of our clients up to the maximum level. Our mission
            is to continuously develop the innovative chemistry required to
            produce a better CLARISS for the consumers and to differentiate our
            products. We strongly believe that customer-oriented production is
            the key to reach out to the hearts of our customers.
          </p>
          <p className="font-bold my-4 text-[18px]">
            <span className="text-accent font-bold mt-2 mb-4">
              Nourish Your Skin, Embrace Your Values:
            </span>
            <br></br>• Certified organic ingredients for a healthy glow.
            <br></br>• Cruelty-free & vegan products that align with your
            beliefs.<br></br>• Non-toxic formulas free from harmful chemicals.
          </p>
          {/* <img className="w-[100%] " src={img1} alt="" /> */}
          <p className="mb-4 font-bold text-[18px]">
            <span className="text-accent font-bold mt-4 mb-2">
              Discover your radiance:
            </span>
            <br></br>• Explore our diverse range for a complete, natural
            routine.<br></br>• Each product crafted with care, bringing you the
            best of nature.<br></br>
          </p>
          <p className="font-bold text-[18px]">
            Clariss Bangladesh invites you to embrace your unique radiance and
            celebrate the beauty that is authentically yours.
          </p>
          <p className="mt-10 font-bold">
            Under License <br />
            Clariss LLC
            <br />
            16192 COASTAL HIGHWAY, LEWES, DELAWARE 19958, SUSSEX COUNTY.
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;

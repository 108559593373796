import React, { useEffect, useState } from "react";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import auth from "../../firebase.init";
import { toast } from "react-hot-toast";
import "firebase/auth";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useAuthState, useSignInWithFacebook } from "react-firebase-hooks/auth";
import { TailSpin } from "react-loader-spinner";
import google from "../../Images/google.jpg";
import facebook from "../../Images/facebook.png";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";

const Login = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [name, setName] = useState("");
  // const [verificationId, setVerificationId] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  // const recaptchaVerifier = useRef(null);
  // const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  // const from = location.state?.from.pathname || "/";
  const [user] = useAuthState(auth);
  const [customer, setCustomer] = useState({});
  const [signInWithGoogle, user1, loading1, error1] = useSignInWithGoogle(auth);
  // const [signInWithFacebook, user2, loading2, error2] = useSignInWithFacebook(auth);

  console.log(user1);

  useEffect(() => {
    if (user?.phoneNumber || user1) {
      if (user1?.user?.email) {
        toast.success("Logged in successfully");
        const email = user1?.user?.email;

        fetch(`https://clariss-backend.vercel.app/postGoogleUser/${email}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            name: user1?.user?.displayName,
            phone: "",
            email: user1?.user?.email,
            role: "customer",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
          });
      }
      // navigate(from, { replace: true });
      navigate("/");
    }
  }, [user?.phoneNumber, user1]);

  useEffect(() => {
    if (ph.length === 11) {
      const phoneNumber = "+88" + ph;
      fetch(`https://clariss-backend.vercel.app/getUser/${phoneNumber}`)
        .then((res) => res.json())
        .then((data) => setCustomer(data));
    }
  }, [ph, user1?.user?.email]);

  const onSignInSubmit = () => {
    setLoading(true);
    // onCaptchaVerify();
    const appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // onSignInSubmit();
        },
      },
      auth
    );
    const phoneNumber = "+88" + ph;
    // const appVerifier = window.recaptchaVerifier;

    // Verify Recaptcha
    appVerifier
      .verify()
      .then(() => {
        // Recaptcha verification successful, proceed with phone number sign-in
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setLoading(false);
            setShowOTP(true);
            toast.success("OTP sent to your number");
          })
          .catch((error) => {
            console.error("Phone number sign-in error:", error);
            toast.error("Something went wrong, please try again");
            setLoading(false);
            window.location.reload();
          });
      })
      .catch((error) => {
        console.error("Recaptcha verification error:", error);
        toast.error("Recaptcha verification failed, please try again");
        setLoading(false);
      });
  };

  const onOtpVerify = () => {
    const phoneNumber = "+88" + ph;
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        toast.success("Logged In Successfully");
        setLoading(false);
      })
      .catch((err) => {
        toast.success("Invalid OTP");
        setLoading(false);
      });
    if (!customer?.phone) {
      fetch("https://clariss-backend.vercel.app/postUser", {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          phone: phoneNumber,
          email: "",
          role: "customer",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        });
    } else {
      console.log("Customer exists");
    }
  };

  return (
    <div className={`2xl:w-[65%] lg:w-[75%] w-[90%] mx-auto mb-20 `}>
      <div id="recaptcha-container"></div>
      <div className="my-10">
        <p className="text-[12px] font-semibold">
          Home
          <FontAwesomeIcon
            className="mx-2"
            icon={faCaretRight}
          ></FontAwesomeIcon>
          Account
        </p>
        <p className="text-xl my-4 font-bold">CUSTOMER LOGIN</p>
      </div>
      {/* <form> */}
      {showOTP ? (
        <div className="mb-4 flex flex-col">
          <p className="text-[14px] font-semibold mb-2">Enter OTP</p>
          <input
            type="number"
            placeholder="Enter OTP"
            className="border px-3 border-secondary border-opacity-50 w-[80%] lg:w-[30vw] h-[40px]"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          {loading ? (
            <button className="px-10 py-2 bg-accent text-primary w-[80%] lg:w-[30vw] mt-6 flex justify-center items-center">
              <TailSpin
                height="25"
                width="25"
                color="#fff"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </button>
          ) : (
            <button
              onClick={onOtpVerify}
              className="px-10 py-2 bg-accent text-primary w-[80%] lg:w-[30vw] mt-6 flex justify-center items-center hover:bg-secondary ease-in-out duration-200"
            >
              Login
            </button>
          )}
        </div>
      ) : (
        <div className="mb-4 flex flex-col">
          <p className="text-[14px] font-semibold mb-2">Full Name</p>
          <input
            type="text"
            placeholder="Enter your name"
            required
            className="border px-3 border-secondary border-opacity-50 mb-4 w-[80%] lg:w-[30vw] h-[40px]"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <p className="text-[14px] font-semibold mb-2">Phone Number</p>
          <input
            type="number"
            required
            placeholder="Enter your phone number"
            className="border px-3 border-secondary border-opacity-50 w-[80%] lg:w-[30vw] h-[40px]"
            value={ph}
            onChange={(e) => setPh(e.target.value)}
          />
          {loading ? (
            <button className="px-10 py-2 bg-accent text-primary w-[80%] lg:w-[30vw] mt-6 flex justify-center items-center">
              <TailSpin
                height="25"
                width="25"
                color="#fff"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </button>
          ) : (
            <button
              onClick={onSignInSubmit}
              className="px-10 py-2 bg-accent text-primary w-[80%] lg:w-[30vw] mt-6 flex justify-center items-center hover:bg-secondary ease-in-out duration-200"
            >
              Send OTP
            </button>
          )}
          <hr className="mt-10 w-[80%] lg:w-[30vw]" />
          <p className="text-center w-[80%] lg:w-[30vw] mt-2">OR</p>
          <div className="flex gap-4">
            {/* <button onClick={() => signInWithFacebook()} className="bg-[#F5F5F5] w-[280px] mt-2 py-2 rounded flex justify-center items-center gap-2">
            <img className="w-6" src={facebook} alt=""/>
              LOGIN WITH FACEBOOK
            </button> */}
            <button
              onClick={() => signInWithGoogle()}
              className="bg-[#F5F5F5] w-[80%] lg:w-[30vw] mt-2 py-2 rounded flex justify-center items-center gap-4"
            >
              <img className="w-6" src={google} alt="" />
              Login With Google
            </button>
          </div>
        </div>
      )}

      {/* <p className="text-error mb-3 font-bold">{error?.message}</p> */}
      {/* <input
          type="submit"
          value="LOGIN"
          className="w-[200px] lg:w-[150px] flex justify-center items-center cursor-pointer bg-secondary text-primary h-[30px] text-xs font-bold"
        />
        <p className="text-[14px] font-semibold my-4">
          Dont have an account ?{" "}
          <Link to="/signup" className="text-accent font-bold">
            Signup
          </Link>
        </p> */}
      {/* </form> */}
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const MobileFoodCare = () => {
  const [linkOneHover, setLinkOneHover] = useState(false);

  return (
    <div className={`mobile-perfume transition-all ease-in-out duration-300`}>
      <button
        onClick={() => setLinkOneHover(!linkOneHover)}
        className={`mr-10 w-[85%] text-start relative ${
          linkOneHover ? "text-accent" : "text-black"
        } font-bold `}
      >
        FOOD CARE
        <FontAwesomeIcon
          className=" w-3 absolute right-0"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </button>

      {/* Face Hover product-Category  */}

      <div
        className={`mobile-menu-hover ${
          linkOneHover ? "grid-transition text-start px-4 py-4 " : ""
        } text-black `}
      >
        <div>
          <hr className=" w-full my-2 text-accent" />
          <Link
            to="/product-category/food care"
            className=" hover:text-accent"
          >
            All Products
          </Link>
          <hr className=" w-full my-2 text-accent" />
          <Link
            to="/product-category/olive oil"
            className=" hover:text-accent"
          >
            Olive Oil
          </Link>
          <hr className=" w-full my-2 text-accent" />
          <Link
            to="/product-category/sunflower oil"
            className=" hover:text-accent"
          >
            Sunflower Oil
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/black seed oil"
            className=" hover:text-accent"
          >
            Black Seed Oil
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/almond oil"
            className=" hover:text-accent"
          >
            Almond Oil
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/castor oil"
            className=" hover:text-accent"
          >
            Castor Oil
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/canned food"
            className=" hover:text-accent"
          >
            Canned Food
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/honey"
            className=" hover:text-accent"
          >
            Honey
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileFoodCare;

import React from "react";
import "./ShopByConcern.css";
import acne from "../../Images/acne.webp";
import Hair from "../../Images/hair care.webp";
import dullskin from "../../Images/Dull Skin.webp";
import tan from "../../Images/Tanning (1).webp";
import oily from "../../Images/Oily skin.webp";
import dry from "../../Images/Dry skin.webp";
import pimple from "../../Images/pimple1.jpg";
import spot from "../../Images/spot.jpg";
import { Link } from "react-router-dom";
import leaf3 from "../../Images/leaf3.png";
import leaf4 from "../../Images/leaf1.png";

const ShopByConcern = () => {
  return (
    <div className="relative overflow-hidden">
      {/* <img
        className="w-[120px] lg:w-[auto] absolute bottom-[0px] lg:bottom-[20px] left-[-30px] lg:left-[-60px] fa-rotate-90"
        alt=""
        src={leaf3}
      /> */}
      <img
        className="w-[100px] bg-img lg:w-[300px] absolute top-[50%] right-0 lg:right-[-40px] fa-rotate-270"
        alt=""
        src={leaf4}
      />
      <div
        className={`flex flex-col px-[10px] items-center sm:px-[20px] lg:px-[0px] lg:w-[90%] mx-auto mb-10 ${
          window.innerWidth >= 1920 ? "2xl:w-[65%]" : "2xl:w-[80%]"
        }`}
      >
        <h2 className="text-center text-xl text-accent font-bold mt-[50px] sm:text-2xl">
          SHOP BY CONCERN
        </h2>
        <div className="w-[100px] mx-auto h-1 bg-accent my-6 mb-10"></div>
        <div className="grid grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 featured-collections ">
          <Link
            to="/product-category/acne"
            className="flex flex-col items-center justify-center mb-10"
          >
            <img src={acne} alt="" />
            <p className="font-bold text-xl mt-4">Acne</p>
          </Link>
          <Link
            to="/product-category/haircare"
            className="flex flex-col items-center justify-center mb-10"
          >
            <img src={Hair} alt="" />
            <p className="font-bold text-xl mt-4">Hair Care</p>
          </Link>
          <Link
            to="/product-category/dull skin"
            className="flex flex-col items-center justify-center mb-10"
          >
            <img src={dullskin} alt="" />
            <p className="font-bold text-xl mt-4">Dull Skin</p>
          </Link>
          <Link
            to="/product-category/tanning"
            className="flex flex-col items-center justify-center mb-10"
          >
            <img src={tan} alt="" />
            <p className="font-bold text-xl mt-4">Tanning</p>
          </Link>
          <Link
            to="/product-category/oily skin"
            className="flex flex-col items-center justify-center mb-10"
          >
            <img src={oily} alt="" />
            <p className="font-bold text-xl mt-4">Oily Skin</p>
          </Link>
          <Link
            to="/product-category/dry skin"
            className="flex flex-col items-center justify-center mb-10"
          >
            <img src={dry} alt="" />
            <p className="font-bold text-xl mt-4">Dry Skin</p>
          </Link>
          <Link
            to="/product-category/pimple"
            className="flex flex-col items-center justify-center mb-10"
          >
            <img
              src={pimple}
              alt=""
              className="rounded-full h-[196px] w-[196px]"
            />
            <p className="font-bold text-xl mt-4">Pimple</p>
          </Link>
          <Link
            to="/product-category/spot"
            className="flex flex-col items-center justify-center mb-10"
          >
            <img
              src={spot}
              alt=""
              className="rounded-full h-[196px] w-[196px]"
            />
            <p className="font-bold text-xl mt-4">Spot</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShopByConcern;

import React, { useContext } from "react";
import { ThemeContext } from "../Contexts/ThemeContext";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import perfume from "../../src/Images/infinite girl.jpg";
import perfume2 from "../../src/Images/Dark desire 02.jpg";
import perfume3 from "../../src/Images/Ventura 01.jpg";
import leaf1 from "../Images/leaf1.png"
import leaf2 from "../Images/leaf2.png"

const SkinTips = () => {
  const [translate, setTranslate] = useState(true);

  return (
    <div className="relative overflow-hidden">
      <img
        className="absolute w-[80px] lg:w-[250px] top-10 lg:top-[55%] left-0 lg:left-[-60px]"
        alt=""
        src={leaf1}
      />
      {/* <img className="absolute w-[100px] lg:w-auto top-14 lg:top-0 right-0" alt="" src={leaf2} /> */}
      <div className="flex flex-col items-center px-[10px] pt-10 2xl:px-[18%] xl:px-[15%] mx-auto relative mb-10">
        <h1 className="text-center text-accent font-bold text-3xl mt-10">
          OUR EXPERTISE
        </h1>
        <div className="w-[130px] mx-auto h-1 bg-accent my-6 mb-10 "></div>
        <div
          className={`flex flex-col sm:flex-row justify-center gap-10 ${
            window.innerWidth <= 1200 ? "flex-wrap" : ""
          }`}
        >
          <div className="w-[380px] cursor-pointer rounded flex flex-col items-center relative bg-white animated-element">
            <img src={perfume2} alt="" className="w-[100%] rounded" />
          </div>
          <div className="w-[380px] cursor-pointer rounded flex flex-col items-center relative bg-white animated-element">
            <img src={perfume} alt="" className="w-[100%] rounded" />
          </div>
          <div className="w-[380px] cursor-pointer rounded flex flex-col items-center relative bg-white animated-element">
            <img src={perfume3} alt="" className="w-[100%] rounded" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkinTips;

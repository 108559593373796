// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyAP_H0n6B4t6r5n1AwX0ZN6qYQhj733Bx8",
  authDomain: "clariss-web.firebaseapp.com",
  projectId: "clariss-web",
  storageBucket: "clariss-web.appspot.com",
  messagingSenderId: "415421434176",
  appId: "1:415421434176:web:f4a4dfc6e1688c6cf112a5",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;
